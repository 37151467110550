import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import firebase from 'firebase/app'
import { ColorModeScript, CSSReset } from '@chakra-ui/react'

import theme from './theme'

var config = {
  apiKey: 'AIzaSyCqMGR8Hbu91HrrOCa_soiDZfgCIGJw2Xg',
  authDomain: 'personal-website-7b3a2.firebaseapp.com',
  databaseURL: 'https://personal-website-7b3a2.firebaseio.com',
  projectId: 'personal-website-7b3a2',
  storageBucket: 'personal-website-7b3a2.appspot.com',
  messagingSenderId: '1008179619527',
}
firebase.initializeApp(config)

ReactDOM.render(
  <React.StrictMode>
    <CSSReset />
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
