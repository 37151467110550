import React from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Navigation from '../components/Navigation'

const About = React.lazy(() => import('../pages/About'))
const Experience = React.lazy(() => import('../pages/Experience'))
const Projects = React.lazy(() => import('../pages/Project'))

const AppRouter = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Navigation>
          <React.Suspense
            fallback={
              <Center>
                <Spinner />
              </Center>
            }
          >
            <Switch>
              <Route exact path="/" component={About} />
              <Route exact path="/experience" component={Experience} />
              <Route exact path="/project" component={Projects} />
            </Switch>
          </React.Suspense>
        </Navigation>
      </React.Fragment>
    </BrowserRouter>
  )
}

export default AppRouter
