import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Collapse,
  Flex,
  useDisclosure,
  useBreakpointValue,
  Text,
  Spacer,
  Stack,
  IconButton,
  useColorMode,
  Container,
  Divider,
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'

import Footer from './Footer'

const NavigationMenu = [
  {
    path: '/',
    pathname: 'About',
  },
  {
    path: '/experience',
    pathname: 'Experience',
  },
  {
    path: '/project',
    pathname: 'Project',
  },
]

interface INavigationMenu {
  path: string
  pathname: string
}

const Navigation: FC = ({ children }) => {
  const location = useLocation()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const menuBreakpoint = useBreakpointValue({ base: false, md: true })
  const iconBreakpoint = useBreakpointValue({ base: 'sm', md: 'md' })
  const { colorMode, toggleColorMode } = useColorMode()

  const CollapseableMenu: FC = () => (
    <Stack p={2}>
      {NavigationMenu.map((menuItem: INavigationMenu) => (
        <Link
          key={menuItem.pathname + 'collapse'}
          to={menuItem.path}
          onClick={onClose}
        >
          <Text
            fontWeight={location.pathname === menuItem.path ? 'bold' : 'medium'}
          >
            {menuItem.pathname}
          </Text>
        </Link>
      ))}
    </Stack>
  )

  const DefaultMenu: FC = () => (
    <Flex hidden={!menuBreakpoint} p={2}>
      <Link to="/">
        <Text fontWeight="bold" p={2}>
          Steven
        </Text>
      </Link>
      <Spacer />
      {NavigationMenu.map((menuItem: INavigationMenu) => (
        <Link key={menuItem.pathname + ' default'} to={menuItem.path}>
          <Text
            fontWeight={location.pathname === menuItem.path ? 'bold' : 'medium'}
            p={2}
          >
            {menuItem.pathname}
          </Text>
        </Link>
      ))}
      <IconButton
        aria-label="theme"
        variant="ghost"
        onClick={toggleColorMode}
        icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        size={iconBreakpoint}
      />
    </Flex>
  )

  return (
    <Container maxW="container.xl">
      <Flex hidden={menuBreakpoint} p={2}>
        <IconButton
          aria-label="nav-menu"
          variant="ghost"
          onClick={onToggle}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          size={iconBreakpoint}
        />
        <Spacer />
        <Link to="/">
          <Text fontWeight="bold">Steven</Text>
        </Link>
        <Spacer />
        <IconButton
          aria-label="theme"
          variant="ghost"
          onClick={toggleColorMode}
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          size={iconBreakpoint}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <CollapseableMenu />
      </Collapse>
      <DefaultMenu />
      <Divider marginBottom={4} />
      {children}
      <Footer />
    </Container>
  )
}

export default Navigation
