import { IconType } from 'react-icons'
import { AiFillLinkedin, AiOutlineFilePdf } from 'react-icons/ai'
import { BiChip } from 'react-icons/bi'
import { CgWebsite } from 'react-icons/cg'
import {
  SiGithub,
  SiC,
  SiCplusplus,
  SiJava,
  SiNodeDotJs,
  SiReact,
  SiHtml5,
  SiCss3,
  SiMongodb,
  SiMysql,
  SiFirebase,
  SiNginx,
  SiVisualstudiocode,
  SiJavascript,
  SiGo,
  SiDocker,
  SiPython,
  SiTypescript,
} from 'react-icons/si'
import resume from '../files/resume.pdf'
import chinaii from '../files/chinaii.jpg'
import vnt from '../files/vnt_home.jpg'
import pegasus from '../files/pegasus_home.jpg'

// Footer page
export interface ILinkInfo {
  name: string
  color: string
  icon: IconType
  link: string
}

export const LinkInfo: ILinkInfo[] = [
  {
    name: 'Github',
    color: 'black',
    icon: SiGithub,
    link: 'https://github.com/csteven15',
  },
  {
    name: 'LinkedIn',
    color: 'blue',
    icon: AiFillLinkedin,
    link: 'https://www.linkedin.com/in/stevenchenucf/',
  },
  {
    name: 'Site',
    color: 'black',
    icon: CgWebsite,
    link: 'https://new.stevenchen.xyz',
  },
  {
    name: 'Resume',
    color: 'red',
    icon: AiOutlineFilePdf,
    link: resume,
  },
]

// About page
export interface ITechStack {
  name: string
  color: string
  icon: IconType
  category: string[]
}

export const TechStack: ITechStack[] = [
  {
    name: 'C',
    color: 'blue',
    icon: SiC,
    category: ['Language'],
  },
  {
    name: 'C++',
    color: 'blue',
    icon: SiCplusplus,
    category: ['Language'],
  },
  {
    name: 'Java',
    color: 'darkred',
    icon: SiJava,
    category: ['Language'],
  },
  {
    name: 'NodeJs',
    color: 'green',
    icon: SiNodeDotJs,
    category: ['Language', 'Web'],
  },

  {
    name: 'React Native',
    color: 'blue',
    icon: BiChip,
    category: ['Framework'],
  },
  {
    name: 'React',
    color: 'blue',
    icon: SiReact,
    category: ['Web', 'Framework'],
  },
  {
    name: 'HTML5',
    color: 'red',
    icon: SiHtml5,
    category: ['Web'],
  },
  {
    name: 'CSS3',
    color: 'blue',
    icon: SiCss3,
    category: ['Web'],
  },
  {
    name: 'MongoDB',
    color: 'green',
    icon: SiMongodb,
    category: ['Web'],
  },
  {
    name: 'MySQL',
    color: 'black',
    icon: SiMysql,
    category: ['Web'],
  },
  {
    name: 'Firebase',
    color: 'yellow.300',
    icon: SiFirebase,
    category: ['Web'],
  },
  {
    name: 'Nginx',
    color: 'green',
    icon: SiNginx,
    category: ['Web', 'Devops'],
  },
  {
    name: 'Visual Studio Code',
    color: 'blue',
    icon: SiVisualstudiocode,
    category: ['IDE'],
  },
  {
    name: 'Javascript',
    color: 'yellow.400',
    icon: SiJavascript,
    category: ['Web', 'Language'],
  },
  {
    name: 'Go',
    color: 'blue',
    icon: SiGo,
    category: ['Language'],
  },
  {
    name: 'Docker',
    color: 'blue',
    icon: SiDocker,
    category: ['Web', 'Devops'],
  },
  {
    name: 'Python',
    color: 'yellow.300',
    icon: SiPython,
    category: ['Devops', 'Language'],
  },
  {
    name: 'Typescript',
    color: 'darkblue',
    icon: SiTypescript,
    category: ['Web', 'Language'],
  },
]

// Project page
export interface IProjectInfo {
  title: string
  type: string
  stack: string
  color: string
  lines: string[]
  date: string
  github: string
  link: string
  image: string
}

export const ProjectInfo: IProjectInfo[] = [
  {
    title: 'New China II',
    type: 'Website Restaurant',
    stack: 'React, Firebae',
    lines: [
      'Designed, developed, and maintained a website to increase customer traffic to restaurant.',
      'Implemented a mobile friendly menu page.',
    ],
    date: 'April 2020',
    color: 'yellow',
    github: 'https://github.com/csteven15/chinaii',
    link: 'https://newchinaii.com',
    image: chinaii,
  },
  {
    title: 'Virtually No Tag',
    type: 'Website for Senior Design',
    stack: 'MongoDB, Express, React, NodeJS',
    lines: [
      'Won best Senior Design Project of 2019 in Computer Science Department.',
      'Allows users to purchase permits and administrators to manage users and view statistics.',
      'Designed and developed a frontend that implements responsive design.',
      'Integrated AWS-Amplify for Authentication, Redux for state management, and Stripe for payments.',
    ],
    date: 'September 2018 - April 2019',
    color: 'gold',
    github: 'https://github.com/csteven15/Senior-Design-Web',
    link: '',
    image: vnt,
  },
  {
    title: 'Pegasus Path Web Application',
    type: 'Academic Project',
    stack: 'TypeScript, HTML, CSS, Bootstrap',
    lines: [
      'Developed a responsive website using Angular 5 and Firebase.',
      'Managed a group of 4 members.',
    ],
    date: 'April 2018',
    color: 'grey',
    github: 'https://github.com/csteven15/COP4331C-18Spring-Group12-Big-Web',
    link: 'https://cop4331c.web.app/',
    image: pegasus,
  },
]

// Experience page
export interface IExperienceInfo {
  title: string
  position: string
  lines: string[]
  date: string
  color: string
}

export const ExperienceInfo: IExperienceInfo[] = [
  {
    title: 'Lockheed Martin',
    position: 'Embedded Systems Engineer',
    lines: [
      'Facilitated daily scrums, retrospectives, and sprint planning ceremonies as Scrum Master.',
      'Designed and developed web app and embedded device for real time communication and metrics collection with 5G network.',
      'Work travel to support integration and testing for software releases.',
      'Developed Linux kernel module and bare metal code to improve DMA transaction by 140% for FPGAs.',
      'Refactored security verification in FPGAs to speed up boot time from 2 minutes to 10 seconds.',
      'Developed QT and Windows Desktop application to send, receive, and record 180Hz video from sensors/HIL system.',
      'Experience in compiling Linux Kernel for application/PCIe device compatibility.',
    ],
    date: 'July 2019 2018 - Present',
    color: 'blue',
  },
  {
    title: 'UCF/Lockheed Martin CWEP',
    position: 'Systems Engineer',
    lines: [
      'Developed RF link Margin Trajectory optimization tools.',
      'Developed an antenna GUI that plots Phi / Theta & Az / El Heatmaps, Polar Plots, and Calculated Spherical Coverage.',
      'Converted Radar Altimeter Antenna Model from Matlab to C++ for an Integrated Flight Simulation System.',
      'Created tools in order to analyze and visualize recorded flight data.',
      'Developed pathfinding algorithm with a complex set of logic.',
      'Created tools for automatic publishing of scientific reports.',
      'Used C# to prototype automatic publishing program for Matlab.',
      'Created automated publishing program using Matlab.',
      'Optimized MatLab code by applying vectorization where needed.',
    ],
    date: 'June 2018 - April 2019',
    color: 'blue',
  },
  {
    title: 'Cardiac Project',
    position: 'Undergraduate Researcher',
    lines: [
      'Processed and analyzed medical scans (MRI, PET, and CT) for the Right and Left Ventricle.',
      'Manually segmented 4, 000 + medical scans for training data.',
      'Developed algorithms for quantification of pathologies and their automatic detection using TensorFlow.',
      'Used Convolutional Neural Networks and Dense Convolutional Neural Networks to segment medical scans.',
    ],
    date: 'October 2016 - December 2017',
    color: 'red',
  },
  {
    title: 'Orange Country Regional History Center',
    position: 'Volunteer',
    lines: [
      'Provided museum tours and communicated technical information to a wide audience.',
      'Trained and recruited new members.',
    ],
    date: 'May 2014 - August 2016',
    color: 'orange',
  },
]
