import {
  Container,
  Flex,
  Icon,
  IconButton,
  LinkOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react'

import { LinkInfo, ILinkInfo } from '../common/Assets'

const Footer = () => (
  <Container maxW="container.xl">
    <br />
    <Flex>
      <Text fontSize={[12, 12, 14, 14, 14]}>&copy; 2021 Steven Chen</Text>
      <Spacer />
      {LinkInfo.map((link: ILinkInfo) => (
        <IconButton
          key={link.name}
          aria-label={link.name}
          icon={
            <LinkOverlay href={link.link} target="_blank">
              <Icon as={link.icon} />
            </LinkOverlay>
          }
          mx={1}
          variant="ghost"
          size="xs"
        />
      ))}
    </Flex>
  </Container>
)

export default Footer
